import React, { useState } from "react";
import { navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import {
  Wrapper,
  BackArrowWrapper,
  Title,
  SectionWrapper,
  SectionTitle,
  Description,
  HorizontalDivider,
  FundsWrapper,
  BackgroundWrapper,
  BlueRectangle,
  BackToTopArrowWrapper,
  FooterWrapper,
  ContactInformation,
  ContactTitle,
  ContactDescription,
  TransitionWrapper,
  TransitionRandomBlock,
  Button,
  ButtonWrapper,
  MobileNavigationBar,
} from "./style";
import Navigation from "../../common/Navigation";
import { PageChange } from "../../common/Scroll";
import { LocationProp, PageThemeType } from "../../../utils/types";
import { fixConjunctions } from "../../../utils/dropTheWord";
import ArrowLeft from "-!svg-react-loader!../../../assets/images/arrowLeft.svg";
import ArrowUp from "-!svg-react-loader!../../../assets/images/arrowUp.svg";
import EuropejskieFunduszeInteligentnyRozwoj from "-!svg-react-loader!../../../assets/images/funds/europejskieFunduszeInteligentnyRozwoj.svg";
import EuropejskieFunduszeProgramRegionalny from "-!svg-react-loader!../../../assets/images/funds/europejskieFunduszeProgramRegionalny.svg";
import EuropejskieFunduszeRozwojuRegionalnego from "-!svg-react-loader!../../../assets/images/funds/europejskieFunduszeRozwojuRegionalnego.svg";
import EuropejskieFunduszeStrukturalneIInwestycyjne from "-!svg-react-loader!../../../assets/images/funds/europejskieFunduszeStrukturalneIInwestycyjne.svg";
import RzeczpospolitaPolska from "-!svg-react-loader!../../../assets/images/funds/rzeczpospolitaPolska.svg";
import NarodoweCentrumBadanIRozwojuImg from "../../../assets/images/funds/narodoweCentrumBadanIRozwoju.png";
import UrzadMarszalkowskiWojewodztwaPomorskiegoImg from "../../../assets/images/funds/urzadMarszalkowskiWojewodztwaPomorskiego.png";
import { chamberlainLink } from "../../../utils/links";
import { PageTitle } from "../../common/PageTitle";

const goBackPath = "/";
const navigationThemes = ["black"] as PageThemeType[];

const transitionRandomBlocks = Array.from(Array(6).keys()).map(c =>
  Array.from(Array(4).keys()).map(r => (
    <TransitionRandomBlock
      key={c + r}
      delay={Math.floor(Math.random() * (800 - 64 + 1)) + 64}
    />
  ))
);

const Offers: React.FC<LocationProp> = ({ location }) => {
  const [, setModalState] = useState<boolean>(false);
  const [pageChange, setPageChange] = useState<PageChange>();

  const { t } = useTranslation("main");
  const i18nContact = useTranslation("contact");

  const scrollToTop = () => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  };

  const goBack = () => {
    setPageChange("next");
    setTimeout(() => {
      navigate(goBackPath);
    }, 800);
  };

  const downloadFile = () => {
    window.location.href = chamberlainLink;
  };

  return (
    <>
      <PageTitle subtitle={t("ourProjects_title")} />
      {/* <MobileNavigationBar /> */}
      <Navigation
        setModalState={setModalState}
        setPageChange={setPageChange}
        navigationThemes={navigationThemes}
        location={location}
        theme="light"
      />
      <Wrapper>
        <BackArrowWrapper onClick={goBack}>
          <ArrowLeft />
        </BackArrowWrapper>
        <SectionWrapper>
          <Title>{t("ourProjects_title")}</Title>
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>{t("ourProjects_spin_title")}</SectionTitle>
          <HorizontalDivider />
          <Description
            dangerouslySetInnerHTML={{
              __html: fixConjunctions(
                t("ourProjects_spin_description").replace(
                  /{:spacer:}/g,
                  isMobile ? "\n" : " "
                )
              ),
            }}
          />
          <FundsWrapper>
            <EuropejskieFunduszeInteligentnyRozwoj />
            <RzeczpospolitaPolska />
            <img src={NarodoweCentrumBadanIRozwojuImg} />
            <EuropejskieFunduszeRozwojuRegionalnego />
          </FundsWrapper>
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>{t("ourProjects_kamerdyner_title")}</SectionTitle>
          <HorizontalDivider />
          <Description
            dangerouslySetInnerHTML={{
              __html: fixConjunctions(
                t("ourProjects_kamerdyner_description").replace(
                  /{:spacer:}/g,
                  isMobile ? "\n" : " "
                )
              ),
            }}
          />
          <FundsWrapper>
            <EuropejskieFunduszeProgramRegionalny />
            <img src={UrzadMarszalkowskiWojewodztwaPomorskiegoImg} />
            <EuropejskieFunduszeStrukturalneIInwestycyjne />
          </FundsWrapper>
          <ButtonWrapper>
            <Button onClick={downloadFile}>{t("download_button")}</Button>
          </ButtonWrapper>
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>{t("ourProjects_asystent_title")}</SectionTitle>
          <HorizontalDivider />
          <Description
            dangerouslySetInnerHTML={{
              __html: fixConjunctions(
                t("ourProjects_asystent_description").replace(
                  /{:spacer:}/g,
                  isMobile ? "\n" : " "
                )
              ),
            }}
          />
          <FundsWrapper>
            <EuropejskieFunduszeInteligentnyRozwoj />
            <RzeczpospolitaPolska />
            <img src={NarodoweCentrumBadanIRozwojuImg} />
            <EuropejskieFunduszeRozwojuRegionalnego />
          </FundsWrapper>
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>{t("ourProjects_smartCommunity_title")}</SectionTitle>
          <HorizontalDivider />
          <Description
            dangerouslySetInnerHTML={{
              __html: fixConjunctions(
                t("ourProjects_smartCommunity_description").replace(
                  /{:spacer:}/g,
                  isMobile ? "\n" : " "
                )
              ),
            }}
          />
          <FundsWrapper>
            <EuropejskieFunduszeInteligentnyRozwoj />
            <RzeczpospolitaPolska />
            <img src={NarodoweCentrumBadanIRozwojuImg} />
            <EuropejskieFunduszeRozwojuRegionalnego />
          </FundsWrapper>
        </SectionWrapper>

        <div>
          <HorizontalDivider />
          <FooterWrapper>
            <ContactInformation>
              <ContactTitle>{i18nContact.t(`oke_title`)}</ContactTitle>
              <ContactDescription>
                {i18nContact.t(`oke_location`)}
              </ContactDescription>
            </ContactInformation>
            <ContactInformation>
              <ContactTitle>{i18nContact.t(`elblag_title`)}</ContactTitle>
              <ContactDescription>
                {i18nContact.t(`elblag_location`)}
              </ContactDescription>
            </ContactInformation>
          </FooterWrapper>
        </div>

        <BackgroundWrapper>
          <BlueRectangle row="1" column="4" />
          <BlueRectangle row="2" column="2 / span 2" />
          <BlueRectangle row="3" column="1" />
          <BlueRectangle row="3" column="4" />
          <BlueRectangle row="4" column="2" />
          <BlueRectangle row="4" column="4" />
          <BlueRectangle row="5" column="3" />
          <BlueRectangle row="6" column="4" />
        </BackgroundWrapper>

        <BackToTopArrowWrapper>
          <ArrowUp onClick={scrollToTop} />
        </BackToTopArrowWrapper>

        <TransitionWrapper animation={pageChange}>
          {transitionRandomBlocks}
        </TransitionWrapper>
      </Wrapper>
    </>
  );
};

export default Offers;
